import { Component, OnInit } from '@angular/core';
import { faTwitterSquare, faFacebookSquare, faInstagramSquare, faYoutubeSquare} from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  title = 'trail-twigs';
  twitter = faTwitterSquare;
  facebook = faFacebookSquare;
  instagram = faInstagramSquare;
  youtube = faYoutubeSquare;
  email = faAt;

  constructor() { }

  ngOnInit() {
  }

}
