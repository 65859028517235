import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GuestnameValidators } from '../shared/validators/guestname.validators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  form = new FormGroup({
    guestname: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      GuestnameValidators.cannotContainSpecialChars
    ]),
    comment: new FormControl('', Validators.required) 
  });

  get guestname() {
    return this.form.get('guestname');
  }
}
