import { AbstractControl, ValidationErrors } from '@angular/forms';
import { resolve } from 'url';

export class GuestnameValidators {
    static cannotContainSpecialChars (control: AbstractControl) : ValidationErrors | null {

        let regex = RegExp(/(\{|\}|\[|\]|\+|\=|\;|\")+/);
        if (regex.test((control.value as string)))
            return { cannotContainSpecialChars: true };
        
        return null;
    }

    static shouldBeUnique(control: AbstractControl) : Promise<ValidationErrors | null> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (control.value === 'mosh')
                    resolve({ shouldBeUnique: true });
                else
                    resolve(null);
            }, 2000);
        });
    }
}